import React from "react";
import AppContext from "components/AppContext";
import InstitutionService from "services/InstitutionService";
import Institution from "interfaces/Institution";
import { useNavigate } from "react-router";
import {
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableBody,
  Typography,
  Table,
  TableHead,
  Tooltip,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function Institutions() {
  const context = React.useContext(AppContext);
  const [institutions, setInstitutions] = React.useState<Institution[]>();
  const navigate = useNavigate();

  React.useEffect(() => {
    context.changeHeaderTitle("Instituciones");
    getInstitutions();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInstitutions = () => {
    InstitutionService.getInstitutions().then((response) => {
      setInstitutions(response);
    });
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            alignItems="flex-end"
          >
            <Grid item alignItems="flex-end">
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => navigate("/panel/institution/new")}
              >
                Nueva Institución
              </Button>
              <Tooltip title="Actualizar">
                <IconButton onClick={() => getInstitutions()}>
                  <RefreshIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {institutions && institutions.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {institutions.map((institution, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {institution.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Tooltip title="Editar">
                      <IconButton
                        aria-label="edit"
                        onClick={() =>
                          navigate(`/panel/institution/${institution.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          No hay instituciones creadas
        </Typography>
      )}
    </Paper>
  );
}
