import React from 'react'
import Paper from '@mui/material/Paper'

function EmptyContainer(props: any) {
    return (
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
            <div className='my-10 mx-8 relative'>
                {props.children}
            </div>
        </Paper>
    )
}

export default EmptyContainer
