import { CircularProgress } from '@mui/material'
import React from 'react'

function Loader(props: any) {
    return (
        <div className={`flex items-center justify-center content-center ${props.screen ? 'h-screen' : 'h-128'} w-full`}>
            <CircularProgress />
        </div>
    )
}

export default Loader
