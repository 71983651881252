import axios from 'axios'
import Institution from 'interfaces/Institution'

const API_URL = process.env.REACT_APP_API_URL + '/institutions'

const getInstitutions = async () => {
    return axios
        .get(API_URL, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const getInstitution = async (id: string) => {
    return axios
        .get(API_URL + '/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const saveInstitution = async (institution: Institution) => {
    return axios
        .post(API_URL, institution, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const patchInstitution = (id: string, institution: Institution) => {
    return axios
        .patch(API_URL + '/' + id, institution, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const exportedMethods = {
    getInstitution,
    getInstitutions,
    patchInstitution,
    saveInstitution
}

export default exportedMethods
