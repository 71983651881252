import React from 'react'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AppContext from 'components/AppContext'
import AuthService from 'services/AuthService'
import Avatar from 'react-avatar'

const lightColor = 'rgba(255, 255, 255, 0.7)'

interface HeaderProps {
  onDrawerToggle: () => void
}

export default function Header(props: HeaderProps) {
  const context = React.useContext(AppContext)

  const { onDrawerToggle } = props

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0} className='unselectable' >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Link
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
                className='cursor-pointer'
                onClick={() => { AuthService.logout(context) }}
              >
                Cerrar sesión
              </Link>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                {/* <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" /> */}
                {context.user && <Avatar name={context.user.firstname + ' ' + context.user.lastname} size={'36'} round={'18px'} />}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
        className='unselectable'
        style={{ paddingBottom: '15px' }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {context.headerTitle}
              </Typography>
            </Grid>
{/*             <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs value={0} textColor="inherit">
          <Tab label="Users" />
          <Tab label="Sign-in method" />
          <Tab label="Templates" />
          <Tab label="Usage" />
        </Tabs>
      </AppBar> */}
    </React.Fragment>
  )
}
