import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Font,
  Image,
} from "@react-pdf/renderer";

import RobotoBlack from "assets/fonts/Roboto/Roboto-Black.ttf";
import RobotoBold from "assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoMedium from "assets/fonts/Roboto/Roboto-Medium.ttf";
import Roboto from "assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoLight from "assets/fonts/Roboto/Roboto-Light.ttf";

Font.register({ family: "Roboto Black", src: RobotoBlack });
Font.register({ family: "Roboto Bold", src: RobotoBold });
Font.register({ family: "Roboto Medium", src: RobotoMedium });
Font.register({ family: "Roboto", src: Roboto });
Font.register({ family: "Roboto Light", src: RobotoLight });

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  view: {
    width: "100%",
    height: "100%",
    position: "absolute",
  }
});

export function RenderPDF(qrCodeDataUri, certificate, imagePath) {
  let PDF = (
    <Document>
      <Page
        orientation="landscape"
        size="A4"
        style={styles.page}
        wrap
      >
        <View style={styles.view}>
          <Image
            src={{
              uri: imagePath,
              method: "GET",
              headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
              body: "",
            }}
          />
        </View>

        <View
          style={{
            flexDirection: "column",
            marginLeft: "15%",
            width: "auto",
            marginRight: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ fontFamily: "Roboto", fontSize: "14" }}>
            {certificate.fields.titleField}
          </Text>

          <View
            style={{
              paddingTop: "20px",
              flexDirection: "row",
              paddingLeft: "30%",
            }}
          >
            <Text style={{ fontFamily: "Roboto Bold", fontSize: "16" }}>
              {certificate.fields.studentField}
            </Text>
          </View>

          <View
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Text style={{ fontFamily: "Roboto", fontSize: "14" }}>
              {certificate.fields.bodyField}
            </Text>
          </View>
          <View
            style={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontFamily: "Roboto", fontSize: "14" }}>
              {certificate.fields.footerField}
            </Text>
          </View>
        </View>

        <Image
          style={{
            position: "absolute",
            bottom: `${certificate.QRCoordinateY}`,
            left: `${certificate.QRCoordinateX}`,
            width: "80",
            height: "80",
          }}
          source={{ uri: qrCodeDataUri }}
        />
      </Page>
    </Document>
  );

  return PDF;
}
