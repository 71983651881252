import Certificate from 'interfaces/Certificate'
import React from 'react'

function ValidationCard({ certificate }: { certificate: Certificate }) {
    return (
        <div className='w-4/5 rounded-md border-2 shadow-md border-green-300'>
            <div className='flex justify-between items-center p-4'>
                <span className='font-semibold text-lg text-gray-700'>{certificate.name}</span>
            </div>
            <div className='p-4'>
                <span className='font-semibold text-lg text-gray-700'>{`${certificate.fields.titleField} ${certificate.fields.studentField} ${certificate.fields.bodyField} ${certificate.fields.footerField}`}</span>
            </div>
        </div>
    )
}

export default ValidationCard
