import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const login = (email: string, password: string, context: any) => {
  return axios
    .post(API_URL + "/users/login",
      {
        email,
        password,
      })
    .then((response) => {
      if (response.data.tokens) {
        context.changeUser(response.data.user)
        localStorage.setItem('token', response.data.tokens.accessToken)
        localStorage.setItem('tenant-id', response.data.user.tenantId)
      }

      return response.data
    })
}

const whoAmI = (context: any) => {
  return axios
    .get(API_URL + "/whoAmI", {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then((response) => {
      if (response.data) {
        context.changeUser(response.data)
      }
      return response
    })
}

const logout = (context: any) => {
  localStorage.removeItem("token")
  window.location.reload()
}

const exportedMethods = {
  login,
  whoAmI,
  logout
}

export default exportedMethods