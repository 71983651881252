import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router";
import TemplateServices from "services/TemplateService";
import TemplateInterface from "interfaces/Template";
import { useNavigate } from "react-router-dom";
import UploadService from "services/UploadService";
import Institution from "interfaces/Institution";
import InstitutionService from "services/InstitutionService";
import { LoadingButton } from "@mui/lab";
import Loader from "components/Loader";
import { Box } from "@mui/system";
import UserInterface from "interfaces/User";
import AppContext from 'components/AppContext';
import AuthService from "services/AuthService";

const S3_SERVER = process.env.REACT_APP_S3_SERVER;

function Template() {
  // const [file, setFile] = useState<File>()
  const context = React.useContext(AppContext)
  const [loading, setLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [institutions, setInstitutions] = React.useState<Institution[]>();
  const [template, setTemplate] = useState<TemplateInterface>({
    name: "",
    institutionId: "",
    titleField: "",
    bodyField: "",
    footerField: "",
    studentField: "",
    QRCoordinateX: "0",
    QRCoordinateY: "0",
    userFirstName:"",
    userLastName:"",
    userId:""
  });
  const [userLogueado,setUserLogueado] =React.useState<UserInterface>({
    email:"",
    firstname:"",
    lastname:"",
    tenantId:"",
    id:""
  });
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getInstitutions();
    getTemplate();
    getUserLogueado();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    if (template.name && template.institutionId) {
      if (params.id! === "new") {
        saveTemplate();
      } else {
        patchTemplate(params.id!, template);
      }
    } else {
      alert("Por favor, complete todos los campos.");
    }
  };

  const getInstitutions = () => {
    InstitutionService.getInstitutions().then((response) => {
      setInstitutions(response);
    });
  };

  const getTemplate = async () => {
    setLoading(true);
    if (params.id && params.id !== "new") {
      const template = await TemplateServices.getTemplate(params.id);
      setTemplate(template);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getUserLogueado = () => {
    AuthService.whoAmI(context).then((data:any)=>{
        setUserLogueado(data.data)
    })
  }

  const patchTemplate = (id: string, template: TemplateInterface) => {
    TemplateServices.patchTemplate(id, template).then((response) => {
      navigate(-1);
    });
  };

  const saveTemplate = () => {
       // Load user logueado
    template.userFirstName=userLogueado.firstname;
    template.userLastName=userLogueado.lastname;
    template.userId=userLogueado.id!;
    TemplateServices.saveTemplate(template).then((response) => {
      navigate(-1);
    });
  };

  const uploadFile = (event: any) => {
    if (params.id && params.id !== "new") {
      let fileList: FileList | null = event.target.files;
      if (fileList) {
        // setFile(fileList[0])
        setFileUploading(true);
        const data = new FormData();
        data.append("file", fileList[0]);
        UploadService.uploadFile(params.id, data).then(
          (response) => {
            alert("Archivo subido correctamente");
            setFileUploading(false);
            getTemplate();
          }
          /* (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()
                    } */
        );
      }
    }
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <Toolbar>
        {loading ? (
          <Loader screen={false} />
        ) : (
          <Grid container spacing={2} alignItems="stretch" direction="column">
            <DialogContent className="mt-5 pb-24">
              <DialogContentText>
                <span className="font-semibold text-lg">
                  {params.id === "new" ? "Crear plantilla" : "Editar plantilla"}
                </span>
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                value={template?.name}
                onChange={(e) => {
                  setTemplate({ ...template, name: e.target.value });
                }}
                id="name"
                label="Nombre de la plantilla"
                fullWidth
                variant="standard"
              />

              <TextField
                margin="dense"
                value={template?.titleField}
                onChange={(e) => {
                  setTemplate({ ...template, titleField: e.target.value });
                }}
                multiline
                id="name"
                label="Cabecera"
                fullWidth
                variant="standard"
              />

              <TextField
                margin="dense"
                value={template?.studentField}
                onChange={(e) => {
                  setTemplate({ ...template, studentField: e.target.value });
                }}
                multiline
                id="name"
                label="Datos del alumno"
                fullWidth
                variant="standard"
              />

              <TextField
                margin="dense"
                value={template?.bodyField}
                onChange={(e) => {
                  setTemplate({ ...template, bodyField: e.target.value });
                }}
                multiline
                id="name"
                label="Cuerpo del certificado"
                fullWidth
                variant="standard"
              />

              <TextField
                margin="dense"
                value={template?.footerField}
                onChange={(e) => {
                  setTemplate({ ...template, footerField: e.target.value });
                }}
                multiline
                id="name"
                label="Pie"
                fullWidth
                variant="standard"
              />

              <div className="pt-8">
                <TextField
                  margin="dense"
                  value={template?.QRCoordinateX}
                  onChange={(e) => {
                    setTemplate({ ...template, QRCoordinateX: e.target.value });
                  }}
                  id="name"
                  label="Eje X QR"
                />

                <TextField
                  margin="dense"
                  value={template?.QRCoordinateY}
                  onChange={(e) => {
                    setTemplate({ ...template, QRCoordinateY: e.target.value });
                  }}
                  id="name"
                  label="Eje Y QR"
                />
              </div>

              {institutions && (
                <Box sx={{ minWidth: 120 }} className="mt-10">
                  <FormControl fullWidth>
                    <InputLabel>Institución</InputLabel>
                    <Select
                      value={template.institutionId}
                      label="Institución"
                      onChange={(e) =>
                        setTemplate({
                          ...template,
                          institutionId: e.target.value,
                        })
                      }
                    >
                      {institutions.map((institution, index) => (
                        <MenuItem key={index} value={institution.id}>
                          {institution.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ marginTop: 4 }}
              >
                <label htmlFor="contained-button-file">
                  <input
                    disabled={params.id === "new" || fileUploading}
                    onChange={(e) => uploadFile(e)}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                  />
                  <LoadingButton
                    loading={fileUploading}
                    disabled={params.id === "new" || fileUploading}
                    variant="contained"
                    endIcon={<FileUploadRoundedIcon />}
                    component="span"
                  >
                    Imagen
                  </LoadingButton>
                </label>
                {params.id && params.id === "new" && (
                  <span className="text-xs">
                    Debe crear la plantilla antes de poder subir una imagen
                  </span>
                )}
              </Stack>

              {template.imagePath && (
                <Box
                  sx={{
                    width: "298px",
                    height: "210px",
                    backgroundImage: `url(${S3_SERVER + template.imagePath})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "4px",
                    marginTop: 4,
                  }}
                />
              )}
            </DialogContent>

            <Stack direction="column" alignItems="flex-end">
              <Stack
                spacing={2}
                direction="row"
                className="statick bottom-0 right-0 m-4"
              >
                <Button
                  disabled={fileUploading}
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={fileUploading}
                  variant="contained"
                  onClick={() => save()}
                >
                  Aceptar
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Toolbar>
    </Paper>
  );
}

export default Template;
