import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from 'pages/login/login'
import Paperbase from 'paperbase/Paperbase'
import AuthServices from 'services/AuthService'
import AppContext from 'components/AppContext'
import Loader from 'components/Loader'
import Validation from 'pages/validation/validation'

const LoggedRouter = () => {
    const [logged, setLogged] = React.useState(false)
    const [checking, setChecking] = React.useState(true)
    const context = React.useContext(AppContext)

    React.useEffect(() => {
        whoAmI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const whoAmI = async () => {
        setChecking(true)
        if (localStorage.getItem('token') === null) {
            setLogged(false)
        } else {
            await AuthServices.whoAmI(context).then(res => {
                if (res.status === 200) {
                    setLogged(true)
                } else {
                    AuthServices.logout(context)
                    setLogged(false)
                }
            }, (error) => {
                AuthServices.logout(context)
                setLogged(false)
            })
        }
        setChecking(false)
    }

    if (checking) {
        return <Loader />
    } else {
        return (
            <Routes>
                <Route path='/' element={logged ? <Navigate to='/panel' /> : <Navigate to='/login' />} />
                <Route path='login/*' element={logged ? <Navigate to='/panel' /> : <Login />} />
                <Route path='panel/*' element={logged ? <Paperbase /> : <Navigate to='/login' />} />
                <Route path='/*' element={logged ? <Navigate to='/panel' /> : <Navigate to='/login' />} />
                <Route path='validation/:tenant' element={<Validation />} />
            </Routes>
        )
    }

}

export default LoggedRouter