import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/upload'

const uploadFile = async (id: string, data: FormData) => {
    return axios
        .post(API_URL + '/' + id, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const exportedMethods = {
    uploadFile
}

export default exportedMethods