import React from 'react'
import './App.css'
import AppRouter from './routers/AppRouter'
import AppContext from './components/AppContext'
import User from 'interfaces/User'
import Configuration from 'interfaces/Configuration'

function App() {

  const [user, setUser] = React.useState({})
  const [headerTitle, setHeaderTitle] = React.useState('')
  const [configuration, setConfiguration] = React.useState({})

  const changeUser = (user: User) => {
    setUser(user)
  }

  const changeHeaderTitle = (title: string) => {
    setHeaderTitle(title)
  }

  const changeConfiguration = (configuration: Configuration) => {
    setConfiguration(configuration)
  }

  const context = {
    headerTitle: headerTitle,
    changeHeaderTitle: changeHeaderTitle,
    user: user,
    changeUser: changeUser,
    configuration: configuration,
    changeConfiguration: changeConfiguration
  }

  return (
    <AppContext.Provider value={context}>
      <AppRouter />
    </AppContext.Provider>
  )
}

export default App
