import ReactDOM from 'react-dom'
import QRCode from 'qrcode.react'
import { RenderPDF } from 'templates/template'
import { pdf } from '@react-pdf/renderer'
import download from 'downloadjs'
import Certificate from 'interfaces/Certificate'
import TemplateService from 'services/TemplateService'

const S3_SERVER = process.env.REACT_APP_S3_SERVER

export const downloadPDF = (certificate: Certificate) => {

    // Buscamos el path de la imagen del template que tiene el certificate
    TemplateService.getTemplate(certificate.templateId).then(template => {

        if (certificate.hasQR && certificate.qrCode) {
            // Primero montamos el QR en el DOM para luego poderlo convertir a imagen
            generateQR(certificate.qrCode)

            // https://stackoverflow.com/questions/60006853/printing-generated-qr-codes-on-a-pdf-file

            // Encontramos el QR en el DOM
            const qrCodeCanvas = document.getElementById('qr') as HTMLCanvasElement

            // Convertimos el QR a imagen
            const qrCodeDataUri = qrCodeCanvas!.toDataURL('image/jpg', 1.0)

            // Creamos el PDF, pasando la imagen como blob
            pdf(RenderPDF(qrCodeDataUri, certificate, (S3_SERVER + template.imagePath))).toBlob().then((blob) => {
                download(blob, `${'pdf'}.pdf`)
            })

            // Eliminamos el div del QR del DOM
            if (document.getElementById('divqr'))
                document.getElementById('divqr')!.remove()

        } else {

            // Si no hay código de qr o no se genera, generamos el PDF sin el qr
            pdf(RenderPDF(null, certificate, (S3_SERVER + template.imagePath))).toBlob().then((blob) => {
                download(blob, `${'pdf'}.pdf`)
            })

        }
    })
}

const generateQR = (text: string) => {
    // Creamos el div que va a contener el QR, lo añadimos al body y lo ocultamos
    let newDiv = document.createElement('div')

    // Le asignamos el id al div, para luego poder añadir el QR encontrándolo por id
    newDiv.setAttribute('id', 'divqr')

    // Ocultamos el div
    newDiv.style.top = '0'
    newDiv.style.left = '0'
    newDiv.style.position = 'fixed'

    // Añadimos el div al body
    document.body.appendChild(newDiv)

    // Renderizamos el QR en el div, encontrándolo por ID, utilizando ReactDOM y la librería QRCode.react
    ReactDOM.render(
        <QRCode bgColor='transparent' id={'qr'} data-qr={text} value={text} />,
        document.getElementById('divqr')
    )
}
