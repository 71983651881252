import axios from 'axios'
import Certificate from 'interfaces/Certificate'

const API_URL = process.env.REACT_APP_API_URL + '/certificates'

const getCertificates = async (filter: unknown) => {
    return axios
        .get(API_URL + '?filter=' + filter, {
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const getPublicCertificates = async (filter: unknown, tenantId: string) => {
    return axios
        .get(API_URL + '?filter=' + filter, {
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${tenantId}`
            }
        })
        .then((response) => {
            return response.data
        })
}


const getCertificate = async (id: string) => {
    return axios
        .get(API_URL + '/' + id, {
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const saveCertificate = async (certificate: Certificate) => {
    return axios
        .post(API_URL, certificate, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const patchCertificate = (id: string, certificate: Certificate) => {
    return axios
        .patch(API_URL + '/' + id, certificate, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const exportedMethods = {
    getCertificate,
    getPublicCertificates,
    getCertificates,
    patchCertificate,
    saveCertificate
}

export default exportedMethods
