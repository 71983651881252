import { Button, TextField } from "@mui/material";
import Certificate from "interfaces/Certificate";
import React from "react";
import CertificateService from "services/CertificateService";
import { useParams } from "react-router";
import ValidationCard from "./validationCard";
import Loader from "components/Loader";

function Validation() {
  const [DNI, setDNI] = React.useState("");
  const [shortDNIError, setShortDNIError] = React.useState(false);
  const [certificates, setCertificates] = React.useState<Certificate[]>([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();

  const filter = () => {
    let json = `{
      "where":{
        "and":[{"name": {"like":"${DNI}", "options":"i"}}]
      },
      "order":"name ASC"
    }`;
    return json;
  };

  const getCertificates = () => {
    setShortDNIError(false);
    setLoading(true);

    if (DNI.length < 8) {
      setShortDNIError(true);
      setLoading(false);
      return;
    }

    CertificateService.getPublicCertificates(filter(), params!.tenant!).then(
      (response) => {
        setCertificates(response);
        setLoading(false);
      }
    );
  };

  return (
    <div className="flex items-center justify-center flex-col w-screen py-8 space-y-8">
      <span>Ingrese su DNI</span>
      <div className="flex flex-col space-x-4 justify-center items-center space-y-4">
        <div className="flex flex-col items-center justify-center">
          <TextField
            label="DNI"
            value={DNI}
            onChange={(e) => setDNI(e.target.value.replace(/[^0-9]/g, ""))}
            variant="outlined"
          />
          {shortDNIError && (
            <span className="text-red-600 font-semibold">
              Longitud del DNI inválida
            </span>
          )}
        </div>
        <Button color="primary" variant="contained" onClick={getCertificates}>
          Buscar
        </Button>
      </div>

      {!loading && certificates.length > 0 && (
        <span>Certificados encontrados:</span>
      )}

      {loading ? (
        <Loader screen={false} />
      ) : (
        certificates.map((certificate) => (
          <ValidationCard key={certificate.id} certificate={certificate} />
        ))
      )}
    </div>
  );
}

export default Validation;
