import React from "react";
import AppContext from "components/AppContext";
import EmptyContainer from "components/EmptyContainer";
import SaveIcon from "@mui/icons-material/Save";
// import Institution from 'interfaces/Institution'
import ConfigurationInterface from "interfaces/Configuration";
// import InstitutionService from 'services/InstitutionService'
import ConfigurationService from "services/ConfigurationService";
import Snackbar from "@mui/material/Snackbar";
// import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { Alert, Button, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";

export default function Configuration() {
  const context = React.useContext(AppContext);
  // const [institutions, setInstitutions] = React.useState<Institution[]>([])
  const [configuration, setConfiguration] =
    React.useState<ConfigurationInterface>();
  const [message, setMessage] = React.useState<boolean>(false);
  let isMounted = true;

  React.useEffect(() => {
    context.changeHeaderTitle("Configuración");
    getConfiguration();
    // getInstitutions()
    
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*   const getInstitutions = () => {
      InstitutionService.getInstitutions().then((response) => {
        if (isMounted)
          setInstitutions(response)
      })
    } */

  const getConfiguration = () => {
    ConfigurationService.getConfigurations().then((response) => {
      if (isMounted) {
        setConfiguration(response[0]);
        context.changeConfiguration(response[0]);
      }
    });
  };

  const patchConfiguration = () => {
    ConfigurationService.patchConfiguration(
      configuration!.id!,
      configuration!
    ).then((response) => {
      setMessage(true);
      getConfiguration();
    });
  };

  return (
    <EmptyContainer>
      {configuration && (
        <div className="relative pb-16">
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              value={configuration.companyName}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  companyName: e.target.value,
                })
              }
              label="Nombre de su empresa"
              id="outlined-basic"
              inputProps={{ maxLength: 18 }}
              variant="outlined"
            />
          </Box>

          {/* <Box sx={{ minWidth: 120 }} className='mt-10'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Institución</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={configuration.defaultInstitution}
                label="Institución"
                onChange={(e) => setConfiguration({ ...configuration, defaultInstitution: e.target.value })}
              >
                {institutions.map((institution, index) => (
                  <MenuItem key={index} value={institution.name}>{institution.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}

          <Stack
            spacing={2}
            direction="row"
            className="absolute bottom-0 right-0"
          >
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => patchConfiguration()}
            >
              Guardar
            </Button>
          </Stack>
        </div>
      )}

      <Snackbar
        open={message}
        onClose={() => setMessage(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setMessage(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Configuración guardada
        </Alert>
      </Snackbar>
    </EmptyContainer>
  );
}
