import Certificates from 'pages/panel/certificates'
import Certificate from 'pages/panel/certificate'
import Configuration from 'pages/panel/configuration'
import Templates from 'pages/panel/templates'
import Template from 'pages/panel/template'
import Institutions from 'pages/panel/institutions'
import Institution from 'pages/panel/institution'
import { Routes, Route, Navigate } from "react-router-dom"

export const PanelRouter = () => (
    <Routes>
        <Route path='/' element={<Certificates />} />
        <Route path="/certificates" element={<Certificates />} />
        <Route path="/certificate/:id" element={<Certificate/>} />
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/template/:id" element={<Template />} />
        <Route path="/institutions" element={<Institutions/>} />
        <Route path="/institution/:id" element={<Institution/>} />
        <Route path='/*' element={<Navigate to='/panel' />} />
    </Routes>
)
