import React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AuthServices from 'services/AuthService'
import AppContext from 'components/AppContext'
import Loader from 'components/Loader'
import { Alert } from '@mui/material'

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://innovare.com.ar/">
        Innovare Software
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

export default function Login() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const context = React.useContext(AppContext)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setError(false)
    setLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    login(data.get('email')?.toString()!, data.get('password')?.toString()!)
  }

  const login = (email: string, password: string) => {
    AuthServices.login(email, password, context).then((response: any) => {
      window.location.reload()
    },
      (error) => {
        setLoading(false)
        setError(true)
      })
  }

  if (loading) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className='flex items-center justify-center content-center h-screen'>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Ingresar
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Dirección de correo"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                {error && <Alert severity="error">Ingreso incorrecto al sistema</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Ingresar
                </Button>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </div>

    )
  }
}