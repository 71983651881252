import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Validation from 'pages/validation/validation'
import LoggedRouter from './LoggedRouter'

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoggedRouter />} />
                <Route path='/*' element={<LoggedRouter />} />
                <Route path='validation/:tenant' element={<Validation />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
