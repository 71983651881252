import React from "react";
import InstitutionServices from "services/InstitutionService";
import InstitutionInterface from "interfaces/Institution";
import { useParams, useNavigate } from "react-router";
import {
  Button,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import Loader from "components/Loader";

function Institution() {
  const [institution, setInstitution] = React.useState<InstitutionInterface>({
    name: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getInstitution();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    if (institution.name) {
      if (params.id === "new") {
        saveInstitution();
      } else {
        patchInstitution(params.id!, institution);
      }
    } else {
      alert("Por favor, complete todos los campos");
    }
  };

  const getInstitution = async () => {
    setLoading(true);
    if (params.id && params.id !== "new") {
      const institution = await InstitutionServices.getInstitution(params.id);
      setInstitution(institution);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const patchInstitution = (id: string, institution: InstitutionInterface) => {
    InstitutionServices.patchInstitution(id, institution).then((response) => {
      navigate(-1);
    });
  };

  const saveInstitution = () => {
    InstitutionServices.saveInstitution(institution!).then((response) => {
      navigate(-1);
    });
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <Toolbar>
        {loading ? (
          <Loader screen={false} />
        ) : (
          <Grid container spacing={2} alignItems="stretch" direction="column">
            <DialogContent className="mt-5 pb-24">
              <DialogContentText>
                <span className="font-semibold text-lg">
                  {params.id === "new"
                    ? "Crear institución"
                    : "Editar institutción"}
                </span>
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                value={institution?.name}
                onChange={(e) => {
                  setInstitution({ ...institution, name: e.target.value });
                }}
                id="name"
                label="Nombre"
                fullWidth
                variant="standard"
              />
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ marginTop: 4 }}
              ></Stack>
            </DialogContent>
            <Stack direction="column" alignItems="flex-end">
              <Stack
                spacing={2}
                direction="row"
                className="statick bottom-0 right-0 m-4"
              >
                <Button variant="contained" onClick={() => navigate(-1)}>
                  Cancelar
                </Button>
                <Button variant="contained" onClick={() => save()}>
                  Aceptar
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Toolbar>
    </Paper>
  );
}

export default Institution;
