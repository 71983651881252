import axios from 'axios'
import Configuration from 'interfaces/Configuration'

const API_URL = process.env.REACT_APP_API_URL + '/configurations'

const getConfigurations = async () => {
    return axios
        .get(API_URL, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const saveConfiguration = async (configuration: Configuration) => {
    return axios
        .post(API_URL, configuration, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const patchConfiguration = (id: string, configuration: Configuration) => {
    return axios
        .patch(API_URL + '/' + id, configuration, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const exportedMethods = {
    getConfigurations,
    patchConfiguration,
    saveConfiguration
}

export default exportedMethods
