import React from "react";
import TemplateService from "services/TemplateService";
import Template from "interfaces/Template";
import AppContext from "components/AppContext";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
/* import DeleteIcon from '@mui/icons-material/Delete' */
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
/* import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle' */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  AppBar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";

export default function Templates() {
  const context = React.useContext(AppContext);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const navigate = useNavigate();
  /*  const [open, setOpen] = React.useState(false) */
  const [inputText, setInputText] = React.useState("");

  let isMounted = true;

  React.useEffect(() => {
    getTemplates();
    context.changeHeaderTitle("Gestión de plantillas");
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplates = () => {
    TemplateService.getTemplates(filter()).then((response) => {
      if (isMounted) setTemplates(response);
    });
  };

  /* const deleteTemplate = (template: Template) => {
      TemplateService.deleteTemplate(template.id!).then(() => {
        getTemplates()
        handleDialog()
      })
    } */

  const duplicateTemplate = (template: Template) => {
    let duplicatedTemplate: Template = { ...template };
    delete duplicatedTemplate.id;
    TemplateService.saveTemplate(duplicatedTemplate).then(() => {
      getTemplates();
    });
  };

  /*   const handleDialog = () => {
    setOpen(!open)
  } */

  const filter = () => {
    let json = `{
      "where":{
        "and":[{"name": {"like":"${inputText}", "options":"i"}}]
      },
      "order":"name ASC"
    }`;
    return json;
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <IconButton onClick={() => getTemplates()}>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <TextField
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                fullWidth
                placeholder="Buscar por nombre"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "default" },
                }}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => navigate("/panel/template/new")}
              >
                Nueva Plantilla
              </Button>
              <Tooltip title="Actualizar">
                <IconButton onClick={() => getTemplates()}>
                  <RefreshIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {templates.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Usuario Creador</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.map((template, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {template.name}
                  </TableCell>
                  
                  <TableCell component="th" scope="row">
                  {template.userFirstName?template.userFirstName:"Carlos"}, {template.userLastName?template.userLastName:"Flores"}
                </TableCell>

                  <TableCell component="th" scope="row" align="right">
                    <Tooltip title="Editar">
                      <IconButton
                        aria-label="edit"
                        onClick={() =>
                          navigate(`/panel/template/${template.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Duplicar">
                      <IconButton
                        aria-label="content-copy"
                        onClick={() => {
                          duplicateTemplate(template);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Eliminar">
                      <IconButton aria-label="delete" onClick={() => { handleDialog() }} >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                  {/* <Dialog open={open} onClose={handleDialog} >
                    <DialogTitle id="alert-dialog-title">
                      {"¿Está seguro que desea elimiar la plantilla?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleDialog}>Canelar</Button>
                      <Button onClick={() => { deleteTemplate(template) }} >
                        Eliminar
                      </Button>
                    </DialogActions>
                  </Dialog> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          No hay plantillas
        </Typography>
      )}
    </Paper>
  );
}
