import React from "react";
import CertificateInterface from "interfaces/Certificate";
import UserInterface from "interfaces/User";
import CertificateService from "services/CertificateService";
import { useParams, useNavigate } from "react-router";
import {
  Button,
  Paper,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import Loader from "components/Loader";
import Template from "interfaces/Template";
import { Box } from "@mui/system";
import TemplateService from "services/TemplateService";
import AuthService from "services/AuthService";
import { downloadPDF } from "services/DownloadPDF";
import AppContext from 'components/AppContext'

function Certificate() {
  const context = React.useContext(AppContext)
  const [userLogueado,setUserLogueado] =React.useState<UserInterface>({
    email:"",
    firstname:"",
    lastname:"",
    tenantId:"",
    id:""
  });
  const [certificate, setCertificate] = React.useState<CertificateInterface>({
    name: "",
    templateId: "",
    qrCode: "",
    fields: {
      titleField: "",
      bodyField: "",
      footerField: "",
      studentField: "",
    },
    hasQR: false,
    userFirstName:"",
    userLastName:"",
    userId:"",
    QRCoordinateX: "0",
    QRCoordinateY: "0",
  });
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState<Template[]>();

  React.useEffect(() => {
    getCertificate();
    getTemplates();
    getUserLogueado();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    // Acá deberia estar formik pero no lo pude hacer andar con typescript y material ni con el yup ni de la forma clásica
    // así que en su lugar hice este horror de verificar que los campos estén llenos uno por uno
    // TODO: Revisar formik
    
    if (certificate.name && certificate.templateId) {
      if (params.id! === "new") saveCertificate();
      else patchCertificate(params.id!, certificate!);
    } else {
      alert("Por favor, complete todos los campos.");
    }
  };

  const getCertificate = async () => {
    setLoading(true);
    if (params.id && params.id !== "new") {
      const certificate = await CertificateService.getCertificate(params.id);
      setCertificate(certificate);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getUserLogueado = () => {
    AuthService.whoAmI(context).then((data:any)=>{
        setUserLogueado(data.data)
    })
  }

  const getTemplates = () => {
    TemplateService.getTemplates("").then((response: any) => {
      setTemplates(response);
    });
  };

  const getTemplate = (templateId: string) => {
    TemplateService.getTemplate(templateId).then((response: any) => {
      let fields = {
        titleField: response.titleField,
        bodyField: response.bodyField,
        footerField: response.footerField,
        studentField: response.studentField,
      };
      setCertificate({
        ...certificate,
        templateId: templateId,
        fields: fields,
        QRCoordinateX: response.QRCoordinateX,
        QRCoordinateY: response.QRCoordinateY,
      });
    });
  };

  const patchCertificate = (id: string, certificate: CertificateInterface) => {
    CertificateService.patchCertificate(id, certificate).then((response) => {
      navigate(-1);
    });
  };

  const saveCertificate = () => {
    // Load user logueado
    certificate.userFirstName=userLogueado.firstname;
    certificate.userLastName=userLogueado.lastname;
    certificate.userId=userLogueado.id!;
    
    CertificateService.saveCertificate(certificate!).then((response) => {
      navigate(-1);
    });
  };

  return (
    <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
      {loading ? (
        <Loader screen={false} />
      ) : (
        <div className="m-4 sm:m-8 flex flex-col">
          <span className="font-semibold text-lg text-gray-700">
            {params.id === "new" ? "Crear certificado" : "Editar certificado"}
          </span>

          <TextField
            autoFocus
            margin="dense"
            value={certificate.name}
            onChange={(e) => {
              setCertificate({ ...certificate, name: e.target.value });
            }}
            label="Nombre del certificado (Nombre y DNI del alumno)"
            fullWidth
            variant="standard"
          />
          <TextField
            multiline
            margin="dense"
            value={certificate.fields.titleField}
            onChange={(e) => {
              setCertificate({
                ...certificate,
                fields: { ...certificate.fields, titleField: e.target.value },
              });
            }}
            label="Cabecera"
            fullWidth
            variant="standard"
          />
          <TextField
            multiline
            margin="dense"
            value={certificate.fields.studentField}
            onChange={(e) => {
              setCertificate({
                ...certificate,
                fields: { ...certificate.fields, studentField: e.target.value },
              });
            }}
            label="Datos del Alumno"
            fullWidth
            variant="standard"
          />
          <TextField
            multiline
            margin="dense"
            value={certificate.fields.bodyField}
            onChange={(e) => {
              setCertificate({
                ...certificate,
                fields: { ...certificate.fields, bodyField: e.target.value },
              });
            }}
            label="Cuerpo del certificado"
            fullWidth
            variant="standard"
          />
          <TextField
            multiline
            margin="dense"
            value={certificate.fields.footerField}
            onChange={(e) => {
              setCertificate({
                ...certificate,
                fields: { ...certificate.fields, footerField: e.target.value },
              });
            }}
            label="Pie"
            fullWidth
            variant="standard"
          />

          <div className="pt-6">
            <span>¿Desea generar el código QR?</span>
            <Checkbox
              checked={certificate.hasQR}
              onChange={(e) => {
                setCertificate({ ...certificate, hasQR: e.target.checked });
              }}
            />
          </div>

          {certificate.hasQR && (
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="dateField"
                value={certificate.qrCode}
                onChange={(e) => {
                  setCertificate({ ...certificate, qrCode: e.target.value });
                }}
                label="Dirección del QR"
                fullWidth
                variant="standard"
              />
              <div className="pt-8">
                <TextField
                  margin="dense"
                  value={certificate.QRCoordinateX}
                  onChange={(e) => {
                    setCertificate({
                      ...certificate,
                      QRCoordinateX: e.target.value,
                    });
                  }}
                  label="Eje X QR"
                />
                <TextField
                  margin="dense"
                  value={certificate.QRCoordinateY}
                  onChange={(e) => {
                    setCertificate({
                      ...certificate,
                      QRCoordinateY: e.target.value,
                    });
                  }}
                  label="Eje Y QR"
                />
              </div>
            </div>
          )}

          {templates && (
            <Box sx={{ minWidth: 120 }} className="mt-10">
              <FormControl fullWidth>
                <InputLabel>Plantilla</InputLabel>
                <Select
                  value={certificate.templateId}
                  label="Plantilla"
                  onChange={(e) => getTemplate(e.target.value)}
                >
                  {templates.map((template, index) => (
                    <MenuItem key={index} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Stack direction="column" alignItems="flex-end">
            <Stack
              spacing={2}
              direction="row"
              className="statick bottom-0 right-0 m-4"
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => downloadPDF(certificate)}
              >
                Descargar Vista Previa
              </Button>
              <Button variant="contained" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={() => save()}>
                Aceptar
              </Button>
            </Stack>
          </Stack>
        </div>
      )}
    </Paper>
  );
}

export default Certificate;
