import React from "react";
import AppContext from "components/AppContext";
import CertificateService from "services/CertificateService";
import Certificate from "interfaces/Certificate";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPDF } from "services/DownloadPDF";
import ReactPaginate from "react-paginate";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  AppBar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Container,
} from "@mui/material";

export default function Certificates() {
  const context = React.useContext(AppContext);
  const [inputText, setInputText] = React.useState("");
  const [certificates, setCertificates] = React.useState<Certificate[]>([]);
  const navigate = useNavigate();
  let isMounted = true;
  const certPerPage = 5;
  const [pageNumber, setPageNumber] = React.useState(0);
  const pagesVisited = pageNumber * certPerPage;
  const pageCount = Math.ceil(certificates.length / certPerPage);

  React.useEffect(() => {
    getCertificates();
    context.changeHeaderTitle("Gestión de certificados");
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    };
  }, []);

  const getCertificates = () => {
    CertificateService.getCertificates(filter()).then((response) => {
      if (isMounted) setCertificates(response);
    });
  };

  const filter = () => {
    let json = `{
          "where":{
            "and":[{"name": {"like":"${inputText}", "options":"i"}}]
          },
          "order":"name ASC"
        }`;
    return json;
  };

  const handlePageChange = (event: any) => {
    setPageNumber(event.selected);
  };
  const displayCertificates = certificates
    .slice(pagesVisited, pagesVisited + certPerPage)
    .map((certificate, index) => (
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {certificate.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {certificate.userFirstName?certificate.userFirstName:"Carlos"}, {certificate.userLastName?certificate.userLastName:"Flores"}
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          <Tooltip title="Descargar Certificado">
            <IconButton
              onClick={() => {
                downloadPDF(certificate);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton
              onClick={() => {
                navigate(`/panel/certificate/${certificate.id}`);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));

  return (
    <div>
      <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <IconButton onClick={() => getCertificates()}>
                  <SearchIcon color="inherit" sx={{ display: "block" }} />
                </IconButton>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  fullWidth
                  placeholder="Buscar por nombre"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={() => navigate("/panel/certificate/new")}
                >
                  Nuevo Certificado
                </Button>
                <Tooltip title="Actualizar">
                  <IconButton onClick={() => getCertificates()}>
                    <RefreshIcon color="inherit" sx={{ display: "block" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {certificates.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Usuario Creador</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{displayCertificates}</TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{ my: 5, mx: 2 }}
            color="text.secondary"
            align="center"
          >
            No hay certificados
          </Typography>
        )}
      </Paper>
      <Container className="pagination">
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          previousLabel="< Anterior"
          nextLabel="Siguiente >"
          breakLabel="..."
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </Container>
    </div>
  );
}
