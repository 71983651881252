import React from 'react'
import AppContext from 'components/AppContext'
import Divider from '@mui/material/Divider'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PanoramaIcon from '@mui/icons-material/Panorama'
import SettingsIcon from '@mui/icons-material/Settings'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { useNavigate } from 'react-router-dom'

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

export default function Navigator(props: DrawerProps) {
  const context = React.useContext(AppContext)
  const navigate = useNavigate()
  const [categories, setCategories] = React.useState([
    {
      id: 'Panel',
      children: [
        { id: 'Gestión de certificados', icon: <FactCheckIcon />, active: false, to: 'certificates' },
        { id: 'Gestión de plantillas', icon: <PanoramaIcon />, active: false, to: 'templates' }
      ],
    },
    {
      id: 'Configuración',
      children: [
        { id: 'Configuración', icon: <SettingsIcon />, active: false, to: 'configuration' },
        { id: 'Instituciones', icon: <AccountBalanceIcon/>, active: false, to: 'institutions'}
      ],
    },
  ])

  const draw = (childrenID: string) => {
    let newCategories = [...categories]
    newCategories.map((category => (
      category.children.map((children => {
        if (childrenID !== children.id) {
          children.active = false
        } else {
          children.active = true
        }
        return null
      }))
    )))
    setCategories(newCategories)
  }

  const { ...other } = props

  return (
    <Drawer variant="permanent" {...other} className='unselectable'>

      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          {context.configuration.companyName}
        </ListItem>

        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>

            {children.map(({ id: childId, icon, active, to }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={active} sx={item} onClick={() => {
                  draw(childId)
                  navigate(to)
                }}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}

      </List>
    </Drawer >
  )
}
