import axios from 'axios'
import Template from 'interfaces/Template'

const API_URL = process.env.REACT_APP_API_URL + '/templates'

const getTemplates = async (filter: unknown) => {
    return axios
        .get(API_URL + '?filter=' + filter, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const getTemplate = async (id: string) => {
    return axios
        .get(API_URL + '/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const saveTemplate = async (template: Template) => {
    return axios
        .post(API_URL, template, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const patchTemplate = async (id: string, template: Template) => {
    return axios
        .patch(API_URL + '/' + id, template, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const deleteTemplate = async (id: string) => {
    return axios
        .delete(API_URL + '/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-tenant-id': `${localStorage.getItem('tenant-id')}`
            }
        })
        .then((response) => {
            return response.data
        })
}

const exportedMethods = {
    getTemplate,
    getTemplates,
    patchTemplate,
    saveTemplate,
    deleteTemplate
}

export default exportedMethods
